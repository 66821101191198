import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EntityAnalyzerInputData from './components/EntityAnalyzerInputData';
import { v4 as uuidv4 } from 'uuid';

const App = () => {
  const [user_tmp_id, setUserTmpId] = useState();

  useEffect(() => {
    const user_tmp_id = localStorage.getItem("user_tmp_id");
    if (user_tmp_id) {
      setUserTmpId(user_tmp_id);
    } else {
      // Generate random user id.
      const user_tmp_id = uuidv4();
      localStorage.setItem("user_tmp_id", user_tmp_id);
      setUserTmpId(user_tmp_id);
    }
  }, []);

  return (
    <div className="App" style={{
      width: "800px", justifyContent: "center", alignItems: 'center', marginLeft: 'auto', marginRight: 'auto'
    }}>
      <h1>Scanwise Analysis Hub</h1>
      <div style={{ borderRadius: 5, borderColor: "lightblue", borderWidth: 2, border: '2px solid lightblue' }}>
        <p>
          Alpha Version - use for testing purposes only.
        </p>
      </div>

      <div>
        <p>
          Instructions:
        </p>
        <ol>
          <li><a style={{ display: 'flex' }}>Enter the official name of the company you want to analyze in the provided field.</a></li>
          <li><a style={{ display: 'flex' }}>Upload financial files related to the company for a more detailed report. You can upload multiple files for a comprehensive analysis. Recognized formats for upload include .docx - .pdf - .xlsx - .txt</a></li>
          <li><a style={{ display: 'flex' }}>Click on the "Generate Report" button to initiate the analysis process.</a></li>
          <li><a style={{ display: 'flex' }}>Please be patient while the report is being generated, as this may take a few minutes to complete.</a></li>
          <li><a style={{ display: 'flex' }}>Once the report is ready, you can click the link to download the report file to your desktop. We recommend reviewing all results.</a></li>
        </ol>
      </div>
      <div style={{ borderRadius: 5, borderColor: "lightblue", borderWidth: 2, border: '2px solid lightblue' }}>
        <EntityAnalyzerInputData user_id={user_tmp_id} />
        <p>

        </p>
      </div>
      <p>

      </p>
      <div style={{ borderRadius: 5, borderColor: "lightblue", borderWidth: 2, border: '2px solid lightblue' }}>
        <p>
          Scanwise @ All Rights Reserved.
        </p>
      </div>
      <p>

      </p>
    </div >
  );
}

export default App;
