import React, { Component } from "react";
import axios from "axios";

export default class EntityAnalyzerInputData extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFiles: {},
            company_name: "",
            additional_names_for_scans: "",
            // This is the default model we will use if user will not select different one.
            // model_identifier: "gpt-3.5-turbo",
            model_identifier: "gpt-4o-mini",
            report_parameters: {},
            responseMsg: {},
        };
    }

    // selectedFiles onchange hander
    handleChangeToSelectedFiles = (e) => {
        var filesArray = [];
        const fileType = e.target.name;

        for (let i = 0; i < e.target.files.length; i++) {
            this.fileValidate(e.target.files[i]);
            filesArray.push(e.target.files[i]);
        }

        var updated_file_map = this.state.selectedFiles;
        updated_file_map[fileType] = filesArray;

        this.setState({
            selectedFiles: updated_file_map
        });
    };

    handleChangeToModel = (e) => {
        this.setState({
            model_identifier: e.target.value,
        });
    };

    handleCompanyNameChange = (e) => {
        this.setState({
            company_name: e.target.value,
        });
    };

    handleAdditionalNameForAdverseMediaSearchChange = (e) => {
        this.setState({
            additional_names_for_scans: e.target.value,
        });
    };

    // submit handler
    submitHandler = (e) => {
        e.preventDefault();

        if (this.state.selectedFiles.length === 0) {
            alert("Input files are not provided!")
            return;
        }

        const data = new FormData();

        data.append("company_name", this.state.company_name);
        data.append("model_identifier", this.state.model_identifier)
        data.append("additional_names_for_scans", this.state.additional_names_for_scans)
        data.append("user_id", this.props.user_id)

        for (const file_type in this.state.selectedFiles) {
            for (var j = 0; j < this.state.selectedFiles[file_type].length; j++) {
                data.append(file_type, this.state.selectedFiles[file_type][j]);
            }
        }

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        // console.log(window.location.href) // Prints: http://localhost:3000/
        // console.log(window.location.hostname) // Print: localhost

        var backend_upload_endpoint_url = window.location.href + 'upload'
        if (window.location.href == 'http://localhost:3000/') {
            backend_upload_endpoint_url = "http://" + window.location.hostname + ":5000/upload"
        }

        axios.post(backend_upload_endpoint_url, data, config)
            .then((response) => {
                console.log(response)
                if (response.status === 200) {
                    this.setState({
                        responseMsg: {
                            status: response.data.status,
                            message: response.data.message,
                            report_file_download_link: response.data.report_file_download_link,
                        },
                    });
                    alert("Successfully generated report!");
                } else {
                    alert("Error generating report! " + response.data.message)
                    this.setState({
                        responseMsg: {
                            status: response.data.status,
                            message: response.data.message,
                            report_file_download_link: response.data.report_file_download_link,
                        },
                    });
                }
            })
            .catch((error) => {
                console.error("Got error: " + error);
                if (error.response) {
                    console.log("Got error response: " + error.response)
                    console.log("Response message: " + error.response.data.message)

                    alert("Error generating report! " + error.response.data.message)
                    this.setState({
                        responseMsg: {
                            status: error.response.data.status,
                            message: error.response.data.message,
                            report_file_download_link: error.response.data.report_file_download_link,
                        },
                    });
                } else {
                    alert("Error generating report!")
                }
            });

    };

    // file validation
    fileValidate = (file) => {
        // TODO: Check file extensions.
        if (
            file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            file.type === "application/pdf" ||
            file.type === "text/plain"
        ) {
            return true;
        } else {
            return false;
        }
    };

    render() {
        return (
            <div className="container py-5">
                <div className="row">
                    <div className="col-lg-12">
                        <form onSubmit={this.submitHandler} encType="multipart/form-data" id="filesForm">
                            <div className="card shadow">

                                <div className="card-header">
                                    <h4 className="card-title fw-bold">
                                        Financial data entries
                                    </h4>
                                    <p>
                                        Upload all files that apply. Please note that the more files you are able to download the more detailed overview you can obtain of the organization.
                                    </p>
                                </div>

                                <div className="card-body">
                                    <div className="form-group py-2">

                                        <label>Company name: </label>
                                        <input type="text" value={this.state.company_name} onChange={this.handleCompanyNameChange} name="company_name" />

                                        <p></p>
                                        <label htmlFor="images">Cap Table files: </label>
                                        <input
                                            type="file"
                                            name="cap_table"
                                            multiple
                                            onChange={this.handleChangeToSelectedFiles}
                                            className="form-control"
                                            accept=".txt,.xlsx,.pdf,.docx"
                                        />

                                        <p></p>
                                        <label htmlFor="images">Profit and Loss Statement files: </label>
                                        <input
                                            type="file"
                                            name="profit_and_loss_statement"
                                            multiple
                                            onChange={this.handleChangeToSelectedFiles}
                                            className="form-control"
                                            accept=".txt,.xlsx,.pdf,.docx"
                                        />

                                        <p></p>
                                        <label htmlFor="images">Cash Flow Statement files: </label>
                                        <input
                                            type="file"
                                            name="cash_flow_statement"
                                            multiple
                                            onChange={this.handleChangeToSelectedFiles}
                                            className="form-control"
                                            accept=".txt,.xlsx,.pdf,.docx"
                                        />

                                        <p></p>
                                        <label htmlFor="images">Budget files: </label>
                                        <input
                                            type="file"
                                            name="budget"
                                            multiple
                                            onChange={this.handleChangeToSelectedFiles}
                                            className="form-control"
                                            accept=".txt,.xlsx,.pdf,.docx"
                                        />

                                        <p></p>
                                        <label htmlFor="images">Audited Financials files: </label>
                                        <input
                                            type="file"
                                            name="audited_financials"
                                            multiple
                                            onChange={this.handleChangeToSelectedFiles}
                                            className="form-control"
                                            accept=".txt,.xlsx,.pdf,.docx"
                                        />

                                        <p></p>
                                        <label htmlFor="images">Business Plan files: </label>
                                        <input
                                            type="file"
                                            name="business_plan"
                                            multiple
                                            onChange={this.handleChangeToSelectedFiles}
                                            className="form-control"
                                            accept=".txt,.xlsx,.pdf,.docx"
                                        />

                                        <p></p>
                                        <label htmlFor="images">Balance Sheet files: </label>
                                        <input
                                            type="file"
                                            name="balance_sheet"
                                            multiple
                                            onChange={this.handleChangeToSelectedFiles}
                                            className="form-control"
                                            accept=".txt,.xlsx,.pdf,.docx"
                                        />

                                        <p></p>
                                        <label htmlFor="images">Form 990 files: </label>
                                        <input
                                            type="file"
                                            name="form_990"
                                            multiple
                                            onChange={this.handleChangeToSelectedFiles}
                                            className="form-control"
                                            accept=".txt,.xlsx,.pdf,.docx"
                                        />

                                        <p></p>
                                        <label htmlFor="images">Select AI model: </label>
                                        <select name="model" defaultValue={this.state.model_identifier} onChange={this.handleChangeToModel}>
                                            <option value="gpt-4o-mini">GPT-4o Mini</option>
                                            <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
                                            <option value="gpt-4o">GPT-4o</option>
                                        </select>

                                    </div>
                                </div>

                                <div className="card-header">
                                    <h4 className="card-title fw-bold">
                                        {/* Sanctions and adverse media scans */}
                                        Adverse media scans
                                    </h4>
                                    {/* <p>
                                        Please see full sanctions list here and adverse media keywords here.
                                    </p> */}
                                </div>

                                <label>Staff name(s) (names separated by coma): </label>
                                <input type="text" value={this.state.additional_names_for_scans} onChange={this.handleAdditionalNameForAdverseMediaSearchChange} name="additional_names_for_scans" />


                                <p></p>
                                <label htmlFor="images">Staff name(s) file (multiple names in the file, one name per line, .txt, .docx or .pdf): </label>
                                <input
                                    type="file"
                                    name="additional_names_for_scans"
                                    multiple
                                    onChange={this.handleChangeToSelectedFiles}
                                    className="form-control"
                                    accept=".txt,.pdf,.docx"
                                />

                                <div className="card-footer">
                                    <p>

                                    </p>
                                    <button type="submit" className="btn btn-success">
                                        Generate Report
                                    </button>
                                </div>

                                {this.state.responseMsg.status === "successs" ? (
                                    <div className="alert alert-success">
                                        <p>

                                        </p>
                                        {this.state.responseMsg.message}
                                        <div style={{ borderRadius: 5, borderColor: "black", borderWidth: 4, border: '4px solid black', margin: 20 }}>
                                            <a href={this.state.responseMsg.report_file_download_link}> Download Generated Report </a>
                                        </div>
                                    </div>
                                ) : this.state.responseMsg.status === "error" ? (
                                    <div className="alert alert-danger">
                                        <p>

                                        </p>
                                        {this.state.responseMsg.message}
                                    </div>
                                ) : (
                                    ""
                                )}

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
